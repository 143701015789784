// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gallery-doors-js": () => import("./../src/pages/gallery/doors.js" /* webpackChunkName: "component---src-pages-gallery-doors-js" */),
  "component---src-pages-gallery-relief-js": () => import("./../src/pages/gallery/relief.js" /* webpackChunkName: "component---src-pages-gallery-relief-js" */),
  "component---src-pages-gallery-sculptures-js": () => import("./../src/pages/gallery/sculptures.js" /* webpackChunkName: "component---src-pages-gallery-sculptures-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-inquire-thank-you-js": () => import("./../src/pages/inquire/thank-you.js" /* webpackChunkName: "component---src-pages-inquire-thank-you-js" */)
}

